import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router";
import Reservation from "./Reservation/Reservation";
import ReservationDetails from "./ReservationDetails/ReservationDetails";

class InitialPlaceholderScreen extends Component {
  constructor(props) {
    super(props);
  }

  onThemeColorChange = (color) => {
    console.log("Changing color with color Initial", color);
    this.props.onThemeColorChange(color);
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path={this.props.match.path}
          render={() => (
            <Reservation onThemeColorChange={this.onThemeColorChange} />
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/details/:bookId`}
          render={() => (
            <ReservationDetails onThemeColorChange={this.onThemeColorChange} />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(InitialPlaceholderScreen);
