import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

// Types and actions creator

const { Types, Creators } = createActions({
  findATable: ["businessId", "locationId", "date", "timeSlot", "guest"],
  findATableSuccess: ["info"],
  clearReserve: {},
  findATableError: {},
});

export const ReserveStateTypes = Types;
export default Creators;

// Initial State
export const INITIAL_RESERVE_STATE = Immutable({
  loading: false,
  available: false,
  dateList: [],
});

// Selectors to derive data from state
export const ReserveSelectors = {
  getReserveDetails: (state) => {
    return {
      loading: state.reserve.loading,
      available: state.reserve.available,
      dateList: state.reserve.dateList,
    };
  },
};

// Reducers
export const findATable = (
  state,
  { businessId, locationId, date, timeSlot, guest }
) => {
  console.log("Find a Table called");
  return {
    loading: true,
    available: false,
    dateList: [],
  };
};

export const findATableSuccess = (state, { info }) => {
  return {
    loading: false,
    available: info.available,
    dateList: info.date_list,
  };
};

export const findATableError = (state, { info }) => {
  return {
    loading: false,
    available: info.available,
    dateList: info.date_list,
  };
};

export const clearReserve = (state) => {
  return {
    loading: false,
    available: false,
    dateList: [],
  };
};

// Setting reducers to the types

export const reducer = createReducer(INITIAL_RESERVE_STATE, {
  [Types.FIND_A_TABLE]: findATable,
  [Types.FIND_A_TABLE_SUCCESS]: findATableSuccess,
  [Types.CLEAR_RESERVE]: clearReserve,
  [Types.FIND_A_TABLE_ERROR]: findATableError,
});
