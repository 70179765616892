import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

// Types and actions creator

const { Types, Creators } = createActions({
  setRestaurantDetails: ["businessName", "locationName"],
  setRestaurantDetailsSuccess: ["info"],
  setRestaurantDetailsError: {},
});

export const RestaurantStateTypes = Types;
export default Creators;

// Initial State
export const INITIAL_RESTAURANT_STATE = Immutable({
  businessName: null,
  businessLocation: null,
  businessId: null,
  locationId: null,
  utc: null,
  utcZone: null,
  logo: null,
  logoHasBusinessName: null,
  backgroundImage: null,
  dateList: [],
  loading: false,
  businessDomain: null,
  locaitonDomain: null,
  businessAddress: null,
  businessCity: null,
  businessProvince: null,
  businessPostalCode: null,
  geo: {
    lat: null,
    long: null,
  },
  restaurantMessage: null,
  themeColor: "#fc6167",
});

// Selectors to derive data from state
export const RestaurantSelectors = {
  getRestaurantDetails: (state) => {
    return {
      loading: state.restaurant.loading,
      businessName: state.restaurant.businessName,
      businessLocation: state.restaurant.businessLocation,
      businessId: state.restaurant.businessId,
      locationId: state.restaurant.locationId,
      utc: state.restaurant.utc,
      utcZone: state.restaurant.utcZone,
      logo: state.restaurant.logo,
      logoHasBusinessName: state.restaurant.logoHasBusinessName,
      backgroundImage: state.restaurant.backgroundImage,
      dateList: state.restaurant.dateList,
      businessDomain: state.restaurant.businessDomain,
      locaitonDomain: state.restaurant.locaitonDomain,
      businessAddress: state.restaurant.businessAddress,
      businessCity: state.restaurant.businessCity,
      businessProvince: state.restaurant.businessProvince,
      businessPostalCode: state.restaurant.businessPostalCode,
      geo: {
        lat: state.restaurant.geo.lat,
        long: state.restaurant.geo.long,
      },
      restaurantMessage: state.restaurant.restaurantMessage,
      themeColor: state.restaurant.themeColor,
    };
  },
};

// Reducers

export const setRestaurantDetails = (state, { businessName, locationName }) => {
  return {
    loading: true,
    businessName: state.businessName,
    businessLocation: state.businessLocation,
    businessId: state.businessId,
    locationId: state.locationId,
    utc: state.utc,
    utcZone: state.utcZone,
    logo: state.logo,
    logoHasBusinessName: state.logoHasBusinessName,
    backgroundImage: state.backgroundImage,
    dateList: state.dateList,
    businessDomain: state.businessDomain,
    locaitonDomain: state.locaitonDomain,
    businessAddress: state.businessAddress,
    businessCity: state.businessCity,
    businessProvince: state.businessProvince,
    businessPostalCode: state.businessPostalCode,
    geo: {
      lat: state.geo.lat,
      long: state.geo.long,
    },
    restaurantMessage: state.restaurantMessage,
    themeColor: state.themeColor,
  };
};

export const setRestaurantDetailsSuccess = (state, { info }) => {
  console.log("Info", info);
  return {
    loading: false,
    businessName: info.business_name,
    businessLocation: info.business_location,
    businessId: info.business_id,
    locationId: info.location_id,
    utc: info.utc,
    utcZone: info.utc_zone,
    logo: info.logo,
    logoHasBusinessName: info.logo_has_business_name,
    backgroundImage: info.background_image,
    dateList: info.date_list,
    businessDomain: info.businessDomain,
    locaitonDomain: info.locaitonDomain,
    businessAddress: info.business_address,
    businessCity: info.business_city,
    businessProvince: info.business_province,
    businessPostalCode: info.business_postal_code,
    geo: {
      lat: info.latitude,
      long: info.longitude,
    },
    restaurantMessage: info.restaurant_message,
    themeColor: info.theme_color,
  };
};

export const setRestaurantDetailsError = (state, { info }) => {
  return {
    loading: false,
    businessName: null,
    businessLocation: null,
    businessId: null,
    locationId: null,
    utc: null,
    utcZone: null,
    logo: null,
    logoHasBusinessName: null,
    backgroundImage: null,
    dateList: [],
    businessDomain: null,
    locaitonDomain: null,
    businessAddress: null,
    businessCity: null,
    businessProvince: null,
    businessPostalCode: null,
    geo: {
      lat: null,
      long: null,
    },
    restaurantMessage: null,
    themeColor: "#fc6167",
  };
};

// Setting reducers to the types

export const reducer = createReducer(INITIAL_RESTAURANT_STATE, {
  [Types.SET_RESTAURANT_DETAILS]: setRestaurantDetails,
  [Types.SET_RESTAURANT_DETAILS_SUCCESS]: setRestaurantDetailsSuccess,
  [Types.SET_RESTAURANT_DETAILS_ERROR]: setRestaurantDetailsError,
});
