import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import LoginActions, { LoginSelectors } from "../../Redux/LoginRedux";
import ReserveActions, { ReserveSelectors } from "../../Redux/ReserveRedux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Box,
  Button,
  TextField,
  MenuItem,
  Divider,
  Modal,
  Menu,
  IconButton,
  Card,
  CardContent,
  Hidden,
} from "@material-ui/core";
import styles from "./style.module.scss";
import { Helmet } from "react-helmet";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DateFnsUtils from "@date-io/moment";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ReserveApi from "../../Services/reserveApi";
import RestaurantActions, {
  RestaurantSelectors,
} from "../../Redux/RestaurantRedux";
import LoginSignup from "../../components/LoginSignup/LoginSignup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendar, faUser } from "@fortawesome/free-solid-svg-icons";
import Timer from "react-compound-timer";
import Loading from "../../components/Loading/Loading";
import AccountCircle from "@material-ui/icons/AccountCircle";
import background from "../../assets/images/background.png";
import background1 from "../../assets/images/background1.png";
import background3 from "../../assets/images/background3.png";
import "react-add-to-calendar/dist/react-add-to-calendar.css";
import AddToCalendar from "react-add-to-calendar";
import InfoIcon from "@material-ui/icons/Info";
import { withTheme } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
import "moment/locale/fr";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

class Reservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      noOfGuest: 2,
      selectedDate: null,
      selectedTime: "",
      timeSelection: [],
      findTable: false,
      sessionId: false,
      getSessionIdTimer: null,
      timerStopped: false,
      reserveDisabled: false,
      showloginModal: false,
      reserveSuccess: false,
      reserveInfo: {
        date: null,
        time: null,
      },
      success: false,
      successPayload: null,
      loading: false,
      menuAnchorElement: null,
      languageAnchorElement: null,
      userMenuOpen: false,
      userLanguageMenuOpen: false,
      notes: "",
      calEvent: null,
      languageHover: false,
    };
  }

  componentDidMount() {
    const restaurantDomain = this.props.match?.params?.restaurant;
    const locationDomain = this.props.match?.params?.location;
    this.props.dispatch(
      RestaurantActions.setRestaurantDetails(restaurantDomain, locationDomain)
    );
    if (navigator.language.indexOf("fr") > -1) {
      moment.locale("fr");
      this.props.i18n.changeLanguage("fr");
    } else {
      moment.locale("en");
      this.props.i18n.changeLanguage("en");
    }
    window.onbeforeunload = function () {
      return "Are you sure to leave this page?";
    };
  }

  handleChange = (event, newValue) => {
    this.setState({
      currentTab: newValue,
    });
  };

  handleGuestChange = (event) => {
    this.setState({
      noOfGuest: event.target.value,
    });
  };

  handleDateChange = (event) => {
    // find timeslots
    var self = this;
    var slots = [];
    var date = moment(event);
    var timeslots = this.props.restaurantDetails.dateList.map((dateSlot) => {
      console.log("Finding one", date);
      if (dateSlot.date == date.format("YYYY-MM-DD")) {
        console.log("Found one");
        slots = dateSlot.time_slot;
      }
    });

    if (slots.length == 0) {
      let apiService = ReserveApi.create();
      apiService
        .findTimeSlots(
          this.props.restaurantDetails.businessId,
          this.props.restaurantDetails.locationId,
          date.format("YYYY-MM-DD")
        )
        .then((response) => {
          if (response.ok && response.data.status !== "Failure") {
            const info = response.data;
            if (info.time_slot.length > 0) {
              this.setState({
                selectedDate: date,
                timeSelection: info.time_slot,
                selectedTime: info.time_slot[0],
              });
            }
          }
        });
    } else {
      this.setState({
        selectedDate: date,
        timeSelection: slots,
        selectedTime: slots[0],
      });
    }
  };

  handleTimeChange = (event) => {
    this.setState({
      selectedTime: event.target.value,
    });
  };

  findTable = () => {
    // if (
    //   this.state.noOfGuest &&
    //   this.state.selectedDate &&
    //   this.state.selectedTime
    // ) {
    this.setState({ findTable: true });
    //   this.props.dispatch(
    //     ReserveActions.findATable(
    //       this.props.restaurantDetails.businessId,
    //       this.props.restaurantDetails.locationId,
    //       this.state.selectedDate,
    //       this.state.selectedTime,
    //       this.state.noOfGuest
    //     )
    //   );
    // }

    // Find table

    this.setState({ loading: true });
    let apiService = ReserveApi.create();
    apiService
      .findATable(
        this.props.restaurantDetails.businessId,
        this.props.restaurantDetails.locationId,
        this.state.selectedDate,
        this.state.selectedTime,
        this.state.noOfGuest
      )
      .then((response) => {
        if (response.ok && response.data.status !== "Failure") {
          const info = response.data;
          console.log(info);
          if (info.available) {
            this.getSessionId(
              this.state.selectedDate.format("YYYY-MM-DD"),
              this.state.selectedTime
            );
          } else {
            this.setState({ loading: false });
          }
          this.props.dispatch(ReserveActions.findATableSuccess(info));
        } else {
          this.setState({ loading: false });
          this.props.dispatch(ReserveActions.findATableError());
        }
      });
  };

  getSessionId = (date, time) => {
    if (this.state.noOfGuest && date && time) {
      let apiService = ReserveApi.create();
      apiService
        .getSessionId(
          this.props.restaurantDetails.businessId,
          this.props.restaurantDetails.locationId,
          date,
          time,
          this.state.noOfGuest
        )
        .then((response) => {
          if (response.data.status != "Failure") {
            if (response.data.available) {
              this.setState({
                sessionId: response.data.session_id,
                currentTab: 1,
                loading: false,
                reserveInfo: {
                  date: date,
                  time: time,
                },
              });
            } else {
              this.setState({ loading: false });
            }
          } else {
            this.setState({ loading: false });
          }
        });
    }
  };

  reserveTable = (date, time) => {
    this.getSessionId(date, time);
  };

  componentWillUnmount() {
    if (this.myInterval) {
      clearInterval(this.myInterval);
    }
  }

  disableReserve = () => {
    this.setState({
      reserveDisabled: true,
    });
  };

  timerStopped = () => {
    this.setState({ timerStopped: true });
  };

  reserveFinalCall = () => {
    if (!this.props.isUserLoggedIn) {
      this.setState({
        showloginModal: true,
      });
    } else {
      let apiService = ReserveApi.create();
      this.setState({ loading: true });
      apiService
        .reserveTable(
          this.props.restaurantDetails.businessId,
          this.props.restaurantDetails.locationId,
          moment(this.state.reserveInfo.date).format("YYYY-MM-DD"),
          this.state.reserveInfo.time,
          this.state.noOfGuest,
          this.state.sessionId,
          this.props.userInfo.customer_id,
          this.state.notes,
          this.props.userToken
        )
        .then((response) => {
          if (response.data.status != "Failure") {
            const successPayload = {
              date: this.state.reserveInfo.date,
              time: this.state.reserveInfo.time,
              noOfGuest: this.state.noOfGuest,
              book_id: response.data.book_id,
            };
            const startDate = moment(
              this.state.reserveInfo.date + " " + this.state.reserveInfo.time
            );
            const endDate = moment(
              this.state.reserveInfo.date + " " + this.state.reserveInfo.time
            ).add(90, "minutes");
            this.setState({
              success: true,
              successPayload: successPayload,
              loading: false,
              event: {
                title:
                  this.props.restaurantDetails.businessName + " Reservation",
                description: this.props.restaurantDetails.messageFromRestaurant,
                location:
                  this.props.restaurantDetails.businessAddress +
                  ", " +
                  this.props.restaurantDetails.businessCity +
                  ", " +
                  this.props.restaurantDetails.businessProvince,
                startTime: startDate,
                endTime: endDate,
              },
            });
            this.resetSuccessful();
          } else {
            toast.error("Error in reserving your table. Please try again!");
            this.setState({ loading: false });
          }
        });
    }
  };

  resetSuccessful = () => {
    this.props.dispatch(ReserveActions.clearReserve());
    this.setState({
      currentTab: 1,
      noOfGuest: 2,
      selectedDate: null,
      selectedTime: "",
      timeSelection: [],
      findTable: false,
      sessionId: false,
      getSessionIdTimer: null,
      minutes: 5,
      seconds: 0,
      reserveDisabled: false,
      showloginModal: false,
      reserveSuccess: true,
      reserveError: false,
      reserveInfo: {
        date: null,
        time: null,
      },
      loading: false,
      notes: "",
    });

    if (this.myInterval) {
      clearInterval(this.myInterval);
    }
  };

  userDifferentAccount = () => {
    this.props.dispatch(LoginActions.logOut());
    this.setState({
      showloginModal: true,
    });
  };

  signOut = () => {
    // Go to login screen on logout for phone order
    this.props.dispatch(LoginActions.logOut());
    this.setState({ userMenuOpen: false });
  };

  handleClose = () => {
    this.setState({ userMenuOpen: false, menuAnchorElement: null });
  };
  handleLanguageClose = () => {
    this.setState({ userLanguageMenuOpen: false, languageAnchorElement: null });
  };

  logIn = () => {
    this.setState({ showloginModal: true, userMenuOpen: false });
  };

  startOver = () => {
    Swal.fire({
      title: this.props.t("Are you sure?"),
      text: this.props.t("Your reservation progress will be reset!"),
      icon: this.props.t("warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: this.props.t("Yes, Start Over!"),
    }).then((result) => {
      if (result.value) {
        this.props.dispatch(ReserveActions.clearReserve());
        this.setState({
          currentTab: 0,
          noOfGuest: 2,
          selectedDate: null,
          selectedTime: "",
          timeSelection: [],
          findTable: false,
          sessionId: false,
          getSessionIdTimer: null,
          minutes: 5,
          seconds: 0,
          reserveDisabled: false,
          showloginModal: false,
          reserveSuccess: true,
          reserveError: false,
          reserveInfo: {
            date: null,
            time: null,
          },
          loading: false,
          notes: "",
        });
        if (this.myInterval) {
          clearInterval(this.myInterval);
        }
      }
    });
  };

  setThemeColor = () => {
    if (
      this.props.restaurantDetails.themeColor &&
      this.props.restaurantDetails.themeColor !==
        this.props.theme.palette.secondary.main
    ) {
      this.props.onThemeColorChange(this.props.restaurantDetails.themeColor);
    }
  };

  changeLanguage = (lan) => {
    if (lan == "en") {
      moment.locale("fr");
      this.props.i18n.changeLanguage("fr");
    } else {
      moment.locale("en");
      this.props.i18n.changeLanguage("en");
    }
  };

  checkPreviousDate = () => {
    if (
      this.state.selectedDate &&
      this.state.selectedDate.startOf("day").unix() <
        moment(this.props.restaurantDetails?.dateList[0]?.date)
          .startOf("day")
          .unix()
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { t, i18n } = this.props;
    if (navigator.language.indexOf("fr") > -1) {
      if (i18n.language !== "fr") {
        moment.locale("fr");
        this.props.i18n.changeLanguage("fr");
      }
    }
    this.setThemeColor();
    let icon = { "calendar-plus-o": "left" };
    if (
      !(
        (this.state.selectedDate && !this.checkPreviousDate()) ||
        this.state.selectedTime
      ) &&
      this.props.restaurantDetails?.dateList?.length
    ) {
      this.setState({
        selectedDate: moment(this.props.restaurantDetails?.dateList[0].date),
        timeSelection: this.props.restaurantDetails?.dateList[0].time_slot,
        selectedTime: this.props.restaurantDetails?.dateList[0].time_slot[0],
      });
    } else if (
      !(
        (this.state.selectedDate && !this.checkPreviousDate()) ||
        this.state.selectedTime
      ) &&
      !this.props.restaurantDetails?.dateList?.length
    ) {
      this.setState({
        selectedDate: moment(),
      });
    }
    const lastDate = this.props.restaurantDetails.dateList[
      this.props.restaurantDetails.dateList.length - 1
    ];

    const maxDateMoment = moment(lastDate?.date).add("days", 70);

    console.log("Max Date", maxDateMoment);

    // var maxDateMoment = moment(maxDate?.date);
    return (
      <>
        {this.props.restaurantDetails?.businessName && (
          <Helmet>
            <title>
              {this.props.restaurantDetails?.businessName} | Reservations
            </title>
          </Helmet>
        )}
        {!this.props.restaurantDetails?.businessName && (
          <Helmet>
            <title>Order On The Go Reservations</title>
          </Helmet>
        )}
        <Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={
              this.props.restaurantDetails.backgroundImage
                ? styles.restaurantBackgroundImage
                : styles.backgroudImage
            }
            style={{
              backgroundImage: this.props.restaurantDetails.backgroundImage
                ? "url(" + this.props.restaurantDetails.backgroundImage + ")"
                : "url(" + background3 + ")",
            }}
          >
            <Grid container justify="center" className={styles.mainSection}>
              <Grid item xs={12} className={styles.topSection}>
                <Grid container justify="center" alignItems="center">
                  {this.props.restaurantDetails?.logo && (
                    <Grid item xs={12}>
                      <div style={{ textAlign: "center" }}>
                        <img
                          className={styles.logo}
                          src={this.props.restaurantDetails?.logo}
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {this.props.restaurantDetails?.logoHasBusinessName !==
                      "1" && (
                      <h1 style={{ textAlign: "center" }}>
                        {this.props.restaurantDetails?.businessName}
                      </h1>
                    )}
                    <span style={{ textAlign: "center" }}>
                      {this.props.restaurantDetails?.businessLocation}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              {this.state.loading && (
                <Grid item xs={12}>
                  <div style={{ marginBottom: 35 }}>
                    <Loading />
                  </div>
                </Grid>
              )}
              {!this.state.loading && (
                <>
                  {!this.state.success && (
                    <Grid item xs={12} className={styles.topSection}>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={this.state.currentTab}
                          onChange={this.handleChange}
                          indicatorColor="secondary"
                          textColor="secondary"
                        >
                          <Tab
                            classes={{ wrapper: styles.tab }}
                            label={this.props.t("Find a Table")}
                            disabled={this.state.currentTab == 0 ? false : true}
                          />
                          <Tab
                            classes={{ wrapper: styles.tab }}
                            label={this.props.t("Guest Info")}
                            disabled={this.state.currentTab == 1 ? false : true}
                          />

                          <Box style={{ marginLeft: "auto" }}>
                            <Hidden xsDown>
                              <Button
                                style={{
                                  marginLeft: "auto",
                                  height: "100%",
                                }}
                                // onMouseOver={() =>
                                //   this.setState({ languageHover: true })
                                // }
                                // onMouseOut={() =>
                                //   this.setState({ languageHover: false })
                                // }
                                onClick={(event) =>
                                  this.changeLanguage(i18n.language)
                                }
                                color="inherit"
                              >
                                {this.state.languageHover ? (
                                  <div className={styles.languageHover}>
                                    Change
                                  </div>
                                ) : i18n.language == "en" ? (
                                  "French"
                                ) : (
                                  "English"
                                )}
                              </Button>
                            </Hidden>
                            <Hidden smUp>
                              <Button
                                style={{
                                  marginLeft: "auto",
                                  height: "100%",
                                  minWidth: 44,
                                }}
                                // onMouseOver={() =>
                                //   this.setState({ languageHover: true })
                                // }
                                // onMouseOut={() =>
                                //   this.setState({ languageHover: false })
                                // }
                                onClick={(event) =>
                                  this.changeLanguage(i18n.language)
                                }
                                color="inherit"
                              >
                                {this.state.languageHover ? (
                                  <div className={styles.languageHover}>
                                    Change
                                  </div>
                                ) : i18n.language == "en" ? (
                                  "FR"
                                ) : (
                                  "EN"
                                )}
                              </Button>
                            </Hidden>
                            <IconButton
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={(event) =>
                                this.setState({
                                  menuAnchorElement: event.target,
                                  userMenuOpen: true,
                                })
                              }
                              style={{
                                marginRight: 15,
                                height: "100%",
                              }}
                              color="inherit"
                            >
                              <AccountCircle />
                            </IconButton>
                            <Menu
                              id="menu-appbar"
                              anchorEl={this.state.menuAnchorElement}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              keepMounted
                              transformOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              open={this.state.userMenuOpen}
                              onClose={this.handleClose}
                            >
                              {(this.state.phoneOrder
                                ? this.props.isStoreUserLoggedIn
                                : this.props.isUserLoggedIn) && (
                                <>
                                  <MenuItem>
                                    <strong>
                                      {this.props.userInfo.customer_name}
                                    </strong>
                                  </MenuItem>
                                  <MenuItem onClick={this.signOut}>
                                    {this.props.t("Logout")}
                                  </MenuItem>
                                </>
                              )}
                              {!this.props.isUserLoggedIn && (
                                <MenuItem onClick={() => this.logIn()}>
                                  {this.props.t("Login")}
                                </MenuItem>
                              )}
                            </Menu>
                          </Box>
                        </Tabs>
                      </AppBar>
                      <TabPanel value={this.state.currentTab} index={0}>
                        <Grid
                          container
                          spacing={2}
                          justify="center"
                          alignItems="flex-end"
                          className={styles.tab}
                        >
                          <Grid item xs={12} sm={3}>
                            <FormControl
                              className={styles.formControl}
                              fullWidth={true}
                            >
                              <InputLabel id="demo-simple-select-label">
                                {this.props.t("No. of Guests")}
                              </InputLabel>
                              <Select
                                value={this.state.noOfGuest}
                                onChange={(event) =>
                                  this.handleGuestChange(event)
                                }
                              >
                                <MenuItem value={2}>
                                  2 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={3}>
                                  3 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={4}>
                                  4 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={5}>
                                  5 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={6}>
                                  6 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={7}>
                                  7 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={8}>
                                  8 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={9}>
                                  9 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={10}>
                                  10 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={11}>
                                  11 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={12}>
                                  12 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={13}>
                                  13 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={14}>
                                  14 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={15}>
                                  15 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={16}>
                                  16 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={17}>
                                  17 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={18}>
                                  18 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={19}>
                                  19 {this.props.t("people")}
                                </MenuItem>
                                <MenuItem value={20}>
                                  20 {this.props.t("people")}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={i18n.language}
                            >
                              <DatePicker
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                label="Date"
                                fullWidth={true}
                                value={this.state.selectedDate}
                                onChange={this.handleDateChange}
                                disablePast={true}
                                maxDate={maxDateMoment}
                                format="Do MMM, dddd"
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormControl
                              className={styles.formControl}
                              fullWidth={true}
                            >
                              <InputLabel id="demo-simple-select-label">
                                {this.props.t("Time")}
                              </InputLabel>
                              <Select
                                value={this.state.selectedTime}
                                onChange={(event) =>
                                  this.handleTimeChange(event)
                                }
                              >
                                {this.state.timeSelection.map((timeslot, i) => {
                                  return (
                                    <MenuItem value={timeslot} index={i}>
                                      {timeslot}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Button
                              variant="contained"
                              color="secondary"
                              fullWidth={true}
                              size="large"
                              onClick={() => this.findTable()}
                            >
                              {this.props.t("Find a Table")}
                            </Button>
                          </Grid>
                        </Grid>
                        {this.state.findTable &&
                          !this.props.reserveDetails.loading && (
                            <Grid container>
                              <Grid item xs={12}>
                                {/* {this.props.reserveDetails.available && (
                                  <>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Box className={styles.successMessage}>
                                          {this.state.selectedTime} table is
                                          available!
                                        </Box>
                                      </Grid>
                                    </Grid>

                                    <Grid container>
                                      <Grid item xs={12} sm={3}>
                                        <Box className={styles.marginTime}>
                                          <Button
                                            fullWidth={true}
                                            color="secondary"
                                            variant="contained"
                                            onClick={() =>
                                              this.getSessionId(
                                                this.state.selectedDate.format(
                                                  "YYYY-MM-DD"
                                                ),
                                                this.state.selectedTime
                                              )
                                            }
                                          >
                                            Reserve Table
                                          </Button>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </>
                                )} */}
                                {!this.props.reserveDetails.available &&
                                  !this.props.reserveDetails.loading && (
                                    <>
                                      <Grid container>
                                        <Grid item xs={12}>
                                          <Box className={styles.errorMessage}>
                                            Sorry {this.state.selectedTime} is
                                            not available!
                                            {this.props.reserveDetails?.dateList
                                              .length > 0 && (
                                              <span>
                                                &nbsp;Please choose from the
                                                available time slots below.
                                              </span>
                                            )}
                                          </Box>
                                        </Grid>
                                      </Grid>
                                      <Grid container>
                                        <Grid item xs={12}>
                                          <Box
                                            className="font16"
                                            style={{ marginBottom: 10 }}
                                          >
                                            Future Availability
                                          </Box>
                                        </Grid>
                                      </Grid>
                                      <Box>
                                        <Divider
                                          style={{
                                            marginTop: 15,
                                            marginBottom: 15,
                                          }}
                                        />
                                        {this.props.reserveDetails.dateList.map(
                                          (date) => {
                                            return (
                                              <>
                                                <Grid container>
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    sm={3}
                                                    justify="center"
                                                  >
                                                    <Box
                                                      className={`${styles.marginTime} subhead`}
                                                      style={{
                                                        paddingTop: 3,
                                                        paddingRight: 10,
                                                        paddingBottom: 15,
                                                      }}
                                                    >
                                                      {moment(date.date).format(
                                                        "dddd, DD MMM"
                                                      )}
                                                    </Box>
                                                  </Grid>
                                                  <Grid item xs={12} sm={9}>
                                                    <Grid container spacing={2}>
                                                      {date.time_slot.map(
                                                        (time) => {
                                                          return (
                                                            <Grid
                                                              item
                                                              xs={6}
                                                              sm={3}
                                                            >
                                                              <Box
                                                                className={
                                                                  styles.marginTime
                                                                }
                                                              >
                                                                <Button
                                                                  fullWidth={
                                                                    true
                                                                  }
                                                                  variant="contained"
                                                                  color="secondary"
                                                                  onClick={() =>
                                                                    this.reserveTable(
                                                                      date.date,
                                                                      time
                                                                    )
                                                                  }
                                                                >
                                                                  {time}
                                                                </Button>
                                                              </Box>
                                                            </Grid>
                                                          );
                                                        }
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Divider
                                                  style={{
                                                    marginTop: 15,
                                                    marginBottom: 15,
                                                  }}
                                                />
                                              </>
                                            );
                                          }
                                        )}
                                        {this.props.reserveDetails.dateList
                                          .length == 0 && (
                                          <div
                                            className={
                                              styles.warningMessageAvailability
                                            }
                                          >
                                            Sorry! there is no availability
                                            around selected time
                                          </div>
                                        )}
                                      </Box>
                                    </>
                                  )}
                              </Grid>
                            </Grid>
                          )}
                      </TabPanel>
                      <TabPanel value={this.state.currentTab} index={1}>
                        <Grid item xs={12}>
                          <Grid container justify="flex-end">
                            <Grid item xs={12}>
                              {this.state.timerStopped && (
                                <div className={styles.warningMessage}>
                                  {this.props.t(
                                    "You can still try to complete your reservation, but this table may no longer be available."
                                  )}
                                </div>
                              )}
                              {!this.state.timerStopped && (
                                <>
                                  <div className={styles.infoMessage}>
                                    <FontAwesomeIcon
                                      icon={faClock}
                                      style={{ marginRight: 10 }}
                                    />
                                    {this.props.t(
                                      "Due to limited availability, we can hold this table for you for"
                                    )}{" "}
                                    &nbsp;
                                    <Timer
                                      initialTime={300000}
                                      direction="backward"
                                      checkpoints={[
                                        {
                                          time: 0,
                                          callback: () => this.timerStopped(),
                                        },
                                      ]}
                                    >
                                      {() => (
                                        <React.Fragment>
                                          <Timer.Minutes />:
                                          <Timer.Seconds />
                                        </React.Fragment>
                                      )}
                                    </Timer>
                                    &nbsp; {this.props.t("minutes")}
                                  </div>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <div className="font20Bold">
                                {this.props.t("Reservation Information")}
                              </div>
                              <div className="font16">
                                {this.state.reserveInfo?.date && (
                                  <>
                                    <div>
                                      <FontAwesomeIcon
                                        icon={faCalendar}
                                        style={{ marginRight: 10 }}
                                        color={
                                          this.props.restaurantDetails
                                            .themeColor
                                            ? this.props.restaurantDetails
                                                .themeColor
                                            : "#fc6167"
                                        }
                                      />
                                      {moment(
                                        this.state.reserveInfo.date
                                      ).format("dddd, D MMMM")}
                                    </div>
                                    <div>
                                      <FontAwesomeIcon
                                        icon={faClock}
                                        style={{ marginRight: 10 }}
                                        color={
                                          this.props.restaurantDetails
                                            .themeColor
                                            ? this.props.restaurantDetails
                                                .themeColor
                                            : "#fc6167"
                                        }
                                      />
                                      {this.state.reserveInfo.time}
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="font16">
                                <FontAwesomeIcon
                                  icon={faUser}
                                  color={
                                    this.props.restaurantDetails.themeColor
                                      ? this.props.restaurantDetails.themeColor
                                      : "#fc6167"
                                  }
                                  style={{ marginRight: 10 }}
                                />
                                {this.state.noOfGuest} {this.props.t("people")}
                              </div>
                              {this.props.isUserLoggedIn && (
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginTop: 15, marginBottom: 15 }}
                                >
                                  <div className="font20Bold">
                                    {this.props.t("Your Information")}
                                  </div>
                                  <div className="font16">
                                    {this.props.userInfo.customer_name}
                                  </div>
                                  <div className="font16">
                                    {this.props.userInfo.customer_phone}
                                  </div>
                                </Grid>
                              )}
                              <TextField
                                id="standard-basic"
                                label={this.props.t("Special Notes")}
                                variant="outlined"
                                value={this.state.notes}
                                className={styles.specialNote}
                                onChange={(event) =>
                                  this.setState({ notes: event.target.value })
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div>
                                <div className="font20Bold">
                                  {this.props.restaurantDetails.businessName}
                                </div>
                                <a
                                  className={styles.addressLink}
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/?api=1&query=${this.props.restaurantDetails.businessName} ${this.props.restaurantDetails.businessAddress}`}
                                >
                                  <div>
                                    {
                                      this.props.restaurantDetails
                                        .businessAddress
                                    }
                                  </div>
                                  <div>
                                    {this.props.restaurantDetails.businessCity},{" "}
                                    {
                                      this.props.restaurantDetails
                                        .businessProvince
                                    }
                                    ,{" "}
                                    {
                                      this.props.restaurantDetails
                                        .businessPostalCode
                                    }
                                  </div>
                                </a>
                                {this.props.restaurantDetails
                                  .restaurantMessage && (
                                  <>
                                    <Grid item xs={12}>
                                      <Card
                                        classes={{
                                          root: styles.allergyWarningStyle,
                                        }}
                                        elevation={0}
                                      >
                                        <CardContent>
                                          <Grid
                                            container
                                            justify="center"
                                            alignItems="top"
                                          >
                                            <Grid item xs={12}>
                                              <>
                                                <div
                                                  className={
                                                    styles.messageFromRestaurant
                                                  }
                                                >
                                                  {this.props.t("Message From")}{" "}
                                                  {
                                                    this.props.restaurantDetails
                                                      .businessName
                                                  }
                                                </div>
                                                <div>
                                                  {
                                                    this.props.restaurantDetails
                                                      .restaurantMessage
                                                  }
                                                </div>
                                              </>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                  </>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <div className={styles.reserveTableSignup}>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="large"
                              onClick={() => this.reserveFinalCall()}
                            >
                              {this.props.t("Reserve Table")}
                            </Button>
                            <Button onClick={() => this.startOver()}>
                              {this.props.t("Start Over")}
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={12}></Grid>
                      </TabPanel>
                    </Grid>
                  )}
                  {this.state.success && (
                    <Grid item xs={12} className={styles.topSection}>
                      <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} alignItems="center">
                          <div
                            className="font16Bold"
                            style={{
                              textAlign: "center",
                              marginBottom: 15,
                            }}
                          >
                            {this.props.t(
                              "Your reservation has been confirmed for"
                            )}{" "}
                            {this.state.successPayload?.noOfGuest}{" "}
                            {this.props.t("people")} on{" "}
                            {moment(this.state.successPayload?.date).format(
                              "dddd, DD MMM YYYY"
                            )}{" "}
                            at {this.state.successPayload?.time}
                          </div>
                          <Divider />
                          <div
                            className="font20Bold"
                            style={{
                              textAlign: "center",
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          >
                            {this.props.restaurantDetails.businessName}
                          </div>
                          <div
                            className="font16Bold"
                            style={{
                              textAlign: "center",
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          >
                            <a
                              className={styles.addressLink}
                              target="_blank"
                              href={`https://www.google.com/maps/search/?api=1&query=${this.props.restaurantDetails.businessName} ${this.props.restaurantDetails.businessAddress}`}
                            >
                              <div>
                                {this.props.restaurantDetails.businessAddress}
                              </div>
                              <div>
                                {this.props.restaurantDetails.businessCity},{" "}
                                {this.props.restaurantDetails.businessProvince},{" "}
                                {
                                  this.props.restaurantDetails
                                    .businessPostalCode
                                }
                              </div>
                            </a>
                            {this.props.restaurantDetails.restaurantMessage && (
                              <>
                                <Grid item xs={12}>
                                  <Card
                                    classes={{
                                      root: styles.allergyWarningStyle,
                                    }}
                                    elevation={0}
                                  >
                                    <CardContent>
                                      <Grid
                                        container
                                        justify="center"
                                        alignItems="top"
                                      >
                                        <Grid item xs={12}>
                                          <>
                                            <div
                                              className={
                                                styles.messageFromRestaurant
                                              }
                                            >
                                              {this.props.t("Message From")}{" "}
                                              {
                                                this.props.restaurantDetails
                                                  .businessName
                                              }
                                            </div>
                                            <div>
                                              {
                                                this.props.restaurantDetails
                                                  .restaurantMessage
                                              }
                                            </div>
                                          </>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </>
                            )}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              color: this.props.restaurantDetails.themeColor
                                ? this.props.restaurantDetails.themeColor
                                : "#fc6167",
                              marginTop: 20,
                              marginBottom: 10,
                            }}
                          >
                            <AddToCalendar
                              buttonLabel={this.props.t("Add to My Calendar")}
                              event={this.state.event}
                              buttonTemplate={icon}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Modal open={this.state.showloginModal} disableBackdropClick>
            <div className={styles.loginModalStyle}>
              <LoginSignup
                nextScreen="null"
                onClose={() => this.setState({ showloginModal: false })}
              />
            </div>
          </Modal>
        </Box>
        {/* <Switch>
          <Route exact path={`${this.props.match.path}/order`} component={OrderScreen} />
          <Route exact path={`${this.props.match.path}/checkout`} component={Checkout} />
          <Route exact path={`${this.props.match.path}/orderHistory`} component={OrderHistory} />
        </Switch> */}
      </>

      // <Button variant="contained" onClick={() => this.goToMenuScreen()}>Get Menu</Button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isUserLoggedIn: LoginSelectors.isUserloggedIn(state),
    restaurantDetails: RestaurantSelectors.getRestaurantDetails(state),
    reserveDetails: ReserveSelectors.getReserveDetails(state),
    userInfo: LoginSelectors.getUserInfo(state),
    userToken: LoginSelectors.getUserToken(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withTheme(withTranslation()(Reservation)))
);
