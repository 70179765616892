import { takeLatest, all } from "redux-saga/effects";

/* ------------- Types ------------- */

import { LoginStateTypes } from "../Redux/LoginRedux";

/* ------------- Sagas ------------- */

import {
  loginRequest,
  signupRequest,
  forgotPassword,
  resetPassword,
} from "./loginSagas";

import { findATable } from "./reserveSagas";

import LoginApi from "../Services/loginApi";

import RestaurantApi from "../Services/restaurantApi";

import ReserveApi from "../Services/reserveApi";

import { setRestaurantDetails } from "./restaurantSagas";
import { RestaurantStateTypes } from "../Redux/RestaurantRedux";
import { ReserveStateTypes } from "../Redux/ReserveRedux";

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const loginApi = LoginApi.create();

const restaurantApi = RestaurantApi.create();

const reserveApi = ReserveApi.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // Login Sagas
    takeLatest(LoginStateTypes.LOGIN_REQUEST, loginRequest, loginApi),
    takeLatest(LoginStateTypes.SIGNUP_REQUEST, signupRequest, loginApi),
    takeLatest(LoginStateTypes.FORGOT_PASSWORD, forgotPassword, loginApi),
    takeLatest(LoginStateTypes.RESET_PASSWORD, resetPassword, loginApi),

    takeLatest(
      RestaurantStateTypes.SET_RESTAURANT_DETAILS,
      setRestaurantDetails,
      restaurantApi
    ),

    takeLatest(ReserveStateTypes.FIND_A_TABLE, findATable, reserveApi),
  ]);
}
