import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Box,
  Tabs,
  Tab,
  AppBar,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import styles from "./style.module.scss";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import LoginActions from "../../Redux/LoginRedux";
import { LoginSelectors } from "../../Redux/LoginRedux";
import Backdrop from "@material-ui/core/Backdrop";
import MaskedInput from "react-text-mask";
import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Loading from "../Loading/Loading";
import { withTranslation } from "react-i18next";

class LoginSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "Login",
      username: "",
      password: "",
      name: "",
      phone: "",
      tab: 0,
      phoneError: false,
      emailError: false,
      passwordError: false,
      forgotPassword: false,
      showPasswordSignup: false,
      showPasswordLogin: false,
    };
  }

  componentDidMount() {
    console.log(this.props.businessInfo);
  }

  componentWillReceiveProps() {
    if (this.props.isUserLoggedIn) {
      this.props.onClose();
    }
  }

  handlePhoneChange = (event) => {
    this.setState({
      phone: event.target.value,
    });
  };

  login = (event) => {
    event.preventDefault();
    if (this.state.password.length < 6) {
      // this.passwordInputRef.current.shake();
      console.log("Password is not correct");
    } else if (this.state.password != "" && this.state.username != "") {
      this.props.dispatch(
        LoginActions.loginRequest(
          this.state.username,
          this.state.password,
          this.props.nextScreen,
          this.props.onClose,
          this.props.match.url
        )
      );
    }
  };

  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  signup = (event) => {
    event.preventDefault();
    var passwordError = false;
    var emailError = false;
    var phoneError = false;
    if (this.state.password.length < 6) {
      passwordError = true;
    }
    if (
      !/^[(][0-9][0-9][0-9][)][ ][0-9][0-9][0-9][-][0-9][0-9][0-9][0-9]/.test(
        this.state.phone
      )
    ) {
      phoneError = true;
    }
    if (
      !/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(this.state.username)
    ) {
      emailError = true;
    }
    if (!passwordError && !emailError && !phoneError) {
      if (
        this.state.name != "" &&
        this.state.username != "" &&
        this.state.password != "" &&
        this.state.phone != ""
      ) {
        this.props.dispatch(
          LoginActions.signupRequest(
            this.state.name,
            this.state.phone,
            this.state.username,
            this.state.password,
            this.props.nextScreen,
            this.props.onClose,
            this.props.match.url
          )
        );
      }
    }
    this.setState({
      phoneError: phoneError,
      emailError: emailError,
      passwordError: passwordError,
    });
  };

  handleChangeView = (event, value) => {
    this.setState({
      tab: value,
    });
  };

  forgotPassword = (event) => {
    event.preventDefault();
    var emailError = false;
    if (
      !/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(this.state.username)
    ) {
      emailError = true;
    }
    if (!emailError) {
      if (this.state.username != "") {
        this.props.dispatch(
          LoginActions.forgotPassword(this.state.username, this.props.onClose)
        );
      }
    }
  };

  render() {
    return (
      <Grid container style={{ backgroundColor: "#fff" }}>
        <Backdrop className={styles.backdrop} open={this.props.loggingLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* {this.props.isUserLoggedIn && (
          <Grid item xs={12} sm={12}>
            <span>Welcome {this.props.userInfo?.name}</span>
          </Grid>
        )} */}
        <AppBar position="static" color="transparent">
          <Tabs
            value={this.state.tab}
            onChange={this.handleChangeView}
            indicatorColor="secondary"
            variant="fullWidth"
          >
            <Tab label={this.props.t("Signup")}>Something Smooth</Tab>
            <Tab
              label={this.state.forgotPassword ? this.props.t("Forgot Password") : this.props.t("Login")}
            >
              asdgasdg
            </Tab>
          </Tabs>
        </AppBar>
        <div
          className={styles.tabpanel}
          role="tabpanel"
          hidden={this.state.tab !== 0}
          id={`wrapped-tabpanel-${0}`}
          aria-labelledby={`wrapped-tab-${0}`}
        >
          {this.state.tab == 0 && (
            <Box p={3}>
              {this.props.loggingLoading && <Loading />}
              {!this.props.loggingLoading && (
                <Grid container>
                  <form className={styles.tabpanel} onSubmit={this.signup}>
                    <div>
                      <FormControl fullWidth={true}>
                        <InputLabel htmlFor="signup-name">{this.props.t("Name")}</InputLabel>
                        <Input
                          id="signup-name"
                          aria-describedby="signup-name-text"
                          onChange={(value) =>
                            this.setState({ name: value.target.value })
                          }
                        />
                      </FormControl>
                    </div>
                    <div>
                      <FormControl fullWidth={true}>
                        <InputLabel htmlFor="signup-phone">{this.props.t("Phone")}</InputLabel>
                        <Input
                          id="signup-phone"
                          aria-describedby="signup-phone-text"
                          onChange={this.handlePhoneChange}
                          name="phone"
                          // onChange={(value) =>
                          //   this.setState({ phone: value.target.value })
                          // }
                          inputComponent={TextMaskCustom}
                        />
                        {this.state.phoneError && (
                          <FormHelperText
                            error
                            id="my-helper-text"
                            label="Error"
                          >
                            {this.props.t("Phone number is invalid")}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div>
                      <FormControl fullWidth={true}>
                        <InputLabel htmlFor="signup-email">{this.props.t("Email")}</InputLabel>
                        <Input
                          fullWidth={true}
                          id="signup-email"
                          aria-describedby="signup-email-text"
                          onChange={(value) =>
                            this.setState({ username: value.target.value })
                          }
                        />
                        {this.state.emailError && (
                          <FormHelperText
                            error
                            id="my-helper-text"
                            label="Error"
                          >
                            {this.props.t("Email is invalid")}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div>
                      <FormControl fullWidth={true}>
                        <InputLabel htmlFor="signup-phone">{this.props.t("Password")}</InputLabel>
                        <Input
                          type="password"
                          id="signup-password"
                          aria-describedby="signup-password-text"
                          type={
                            this.state.showPasswordSignup ? "text" : "password"
                          }
                          onChange={(value) =>
                            this.setState({ password: value.target.value })
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  this.setState({
                                    showPasswordSignup: !this.state
                                      .showPasswordSignup,
                                  })
                                }
                                onMouseDown={(event) => event.preventDefault()}
                              >
                                {this.state.showPasswordSignup ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {this.state.passwordError && (
                          <FormHelperText
                            error
                            id="my-helper-text"
                            label="Error"
                          >
                            {this.props.t("Password must be atleast 6 characters")}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <br />
                    <div>
                      <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                      >
                        {this.props.t("Signup")}
                      </Button>
                      <Button
                        onClick={() => this.props.onClose()}
                        variant="text"
                      >
                        {this.props.t("Close")}
                      </Button>
                    </div>
                  </form>
                </Grid>
              )}
            </Box>
          )}
        </div>
        <div
          role="tabpanel"
          className={styles.tabpanel}
          hidden={this.state.tab !== 1}
          id={`wrapped-tabpanel-${1}`}
          aria-labelledby={`wrapped-tab-${1}`}
        >
          {this.state.tab == 1 && (
            <Box p={3}>
              {this.props.loggingLoading && <Loading />}
              {!this.props.loggingLoading && (
                <Grid container>
                  <form
                    className={styles.tabpanel}
                    onSubmit={
                      this.state.forgotPassword
                        ? this.forgotPassword
                        : this.login
                    }
                  >
                    <div>
                      <FormControl fullWidth={true}>
                        <InputLabel htmlFor="signup-email">{this.props.t("Email")}</InputLabel>
                        <Input
                          fullWidth={true}
                          id="signup-email"
                          aria-describedby="signup-email-text"
                          onChange={(username) =>
                            this.setState({ username: username.target.value })
                          }
                        />
                      </FormControl>
                    </div>
                    {!this.state.forgotPassword && (
                      <div>
                        <FormControl fullWidth={true}>
                          <InputLabel htmlFor="signup-phone">
                          {this.props.t("Password")}
                          </InputLabel>
                          <Input
                            type="password"
                            id="signup-password"
                            aria-describedby="signup-password-text"
                            type={
                              this.state.showPasswordLogin ? "text" : "password"
                            }
                            onChange={(value) =>
                              this.setState({ password: value.target.value })
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    this.setState({
                                      showPasswordLogin: !this.state
                                        .showPasswordLogin,
                                    })
                                  }
                                  onMouseDown={(event) =>
                                    event.preventDefault()
                                  }
                                >
                                  {this.state.showPasswordLogin ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                    )}
                    <br />

                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <Button
                          color="secondary"
                          variant="contained"
                          type="submit"
                        >
                          {this.state.forgotPassword ? this.props.t("Submit") : this.props.t("Login")}
                        </Button>

                        <Button
                          onClick={() => this.props.onClose()}
                          variant="text"
                        >
                          {this.props.t("Close")}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className={styles.forgotPasswordButton}>
                          {this.state.forgotPassword && (
                            <>
                              <Button
                                style={{ marginBottom: "10px" }}
                                color="secondary"
                                onClick={() =>
                                  this.setState({ forgotPassword: false })
                                }
                              >
                                {this.props.t("Login")}
                              </Button>
                              <br />
                            </>
                          )}
                          {!this.state.forgotPassword && (
                            <>
                              <Button
                                style={{ marginBottom: "10px" }}
                                color="secondary"
                                onClick={() =>
                                  this.setState({ forgotPassword: true })
                                }
                              >
                                {this.props.t("Forgot Password")}
                              </Button>
                              <br />
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              )}
            </Box>
          )}
        </div>
      </Grid>
    );
  }
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      showMask
    />
  );
}

const mapStateToProps = (state) => {
  return {
    isUserLoggedIn: LoginSelectors.isUserloggedIn(state),
    loggingLoading: LoginSelectors.isLoading(state),
    loginError: LoginSelectors.getLoginError(state),
    signupError: LoginSelectors.getSignupError(state),
    signupErrorMessage: LoginSelectors.getSignupErrorMessage(state),
    userInfo: LoginSelectors.getUserInfo(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withTranslation()(LoginSignup))
);
