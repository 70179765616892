import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "No. of Guests": "No. of Guests",
      "people": "people",
      "Time": "Time",
      "Find a Table": "Find a Table",
      "Guest Info": "Guest Info",
      "Login": "Login",
      "Signup": "Signup",
      "Logout": "Logout",
      "Name": "Name",
      "Phone": "Phone",
      "Email": "Email",
      "Password": "Password",
      "Close": "Close",
      "Phone number is invalid": "Phone number is invalid",
      "Email is invalid":"Email is invalid",
      "Password must be atleast 6 characters": "Password must be atleast 6 characters",
      "Forgot Password": "Forgot Password",
      "Submit": "Submit",
      "Due to limited availability, we can hold this table for you for": "Due to limited availability, we can hold this table for you for",
      "minutes": "minutes",
      "Reservation Information": "Reservation Information",
      "Special Note": "Special Note",
      "Reserve Table": "Reserve Table",
      "Start Over": "Start Over",
      "Are you sure?": "Are you sure?",
      "Your reservation progress will be reset!": "Your reservation progress will be reset!",
      "Yes, Start Over!": "Yes, Start Over!",
      "Cancel": "Cancel",
      "Your Information": "Your Information",
      "Welcome": "Welcome",
      "Your reservation has been confirmed for":"Your reservation has been confirmed for",
      "Add to My Calendar":"Add to My Calendar",
      "warning": "warning",
      "You can still try to complete your reservation, but this table may no longer be available.": "You can still try to complete your reservation, but this table may no longer be available.",
      "Due to limited availability, we can hold this table for you for": "Due to limited availability, we can hold this table for you for",
      "Message From": "Message From"
    }
  },
  fr: {
    translation: {
      "No. of Guests": "No. des invités",
      "people": "personnes",
      "Time": "Temps",
      "Find a Table": "Trouver une Table",
      "Guest Info": "Info invité",
      "Login": "S'identifier",
      "Signup": "S'inscrire",
      "Logout": "Se déconnecter",
      "Name": "Nom",
      "Phone": "Téléphone",
      "Email": "Email",
      "Password": "Mot de passe",
      "Close": "Fermer",
      "Phone number is invalid": "Le numéro de téléphone n'est pas valide",
      "Email is invalid":"Le courriel est invalide",
      "Password must be atleast 6 characters": "Le mot de passe doit être au moins de 6 caractères ",
      "Forgot Password": "Mot de passe oublié",
      "Submit": "Soumettre",
      "Due to limited availability, we can hold this table for you for": "En raison de la disponibilité limitée, nous pouvons tenir cette table pour vous pendant:",
      "minutes": "minutes",
      "Reservation Information": "Informations sur la réservation",
      "Special Notes": "Notes spéciale",
      "Reserve Table": "Réserver une table",
      "Start Over": "Recommencer",
      "Are you sure?": "Êtes-vous sûr?",
      "Your reservation progress will be reset!": "La progression de votre réservation sera réinitialisée!",
      "Yes, Start Over!": "Oui, recommencez!",
      "Cancel": "Annuler",
      "Welcome": "Bienvenu(e)",
      "Your Information": "Vos Informations",
      "Your reservation has been confirmed for":"Votre réservation a été confirmée pour",
      "Add to My Calendar":"Ajouter à mon calendrier",
      "warning": "attention",
      "You can still try to complete your reservation, but this table may no longer be available.": "Vous pouvez toujours essayer de finaliser votre réservation, mais cette table peut ne plus être disponible.",
      "Due to limited availability, we can hold this table for you for": "En raison de la disponibilité limitée, nous pouvons conserver cette table pour vous pendant",
      "Message From": "Message de"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;