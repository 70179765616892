import { call, put, delay } from "redux-saga/effects";
import ReserveActions from "../Redux/ReserveRedux";

export function* findATable(api, action) {
  const { businessId, locationId, date, timeSlot, guest } = action;
  console.log("Sagas");
  const response = yield call(
    api.findATable,
    businessId,
    locationId,
    date,
    timeSlot,
    guest
  );
  if (response.ok && response.data.status !== "Failure") {
    const info = response.data;
    yield put(ReserveActions.findATableSuccess(info));
  } else {
    yield put(ReserveActions.findATableError());
  }
}
