import apisauce from "apisauce";
import { api_key } from "./apiKey";
import { baseUrl } from "./baseUrl";

const create = (baseURL = baseUrl) => {
  const restaurantApi = apisauce.create({
    baseURL,
  });

  const findATable = (businessId, locationId, date, timeSlot, guest) => {
    console.log("Find a table called again");
    const formData = new FormData();
    formData.append("business_id", businessId);
    formData.append("location_id", locationId);
    formData.append("date", date);
    formData.append("time_slot", timeSlot);
    formData.append("guest", guest);
    formData.append("api_key", api_key);
    return restaurantApi.post("findtable.php", formData);
  };

  const findTimeSlots = (businessId, locationId, date) => {
    const formData = new FormData();
    formData.append("business_id", businessId);
    formData.append("location_id", locationId);
    formData.append("date", date);
    formData.append("api_key", api_key);
    return restaurantApi.post("gettimeslots.php", formData);
  };

  const getSessionId = (businessId, locationId, date, timeSlot, guest) => {
    const formData = new FormData();
    formData.append("business_id", businessId);
    formData.append("location_id", locationId);
    formData.append("date", date);
    formData.append("time_slot", timeSlot);
    formData.append("guest", guest);
    formData.append("api_key", api_key);
    return restaurantApi.post("getsessionid.php", formData);
  };

  const reserveTable = (
    businessId,
    locationId,
    date,
    timeSlot,
    guest,
    sessionId,
    customerId,
    notes,
    token
  ) => {
    const formData = new FormData();
    formData.append("business_id", businessId);
    formData.append("location_id", locationId);
    formData.append("date", date);
    formData.append("time_slot", timeSlot);
    formData.append("guest", guest);
    formData.append("session_id", sessionId);
    formData.append("api_key", api_key);
    formData.append("customer_id", customerId);
    formData.append("notes", notes);
    formData.append("token", token);
    return restaurantApi.post("reservetable.php", formData);
  };

  const findReservation = (businessId, locationId, token, bookId) => {
    const formData = new FormData();
    formData.append("business_id", businessId);
    formData.append("location_id", locationId);
    formData.append("api_key", api_key);
    formData.append("token", token);
    formData.append("book_id", bookId);
    return restaurantApi.post("getreservationinfo.php", formData);
  };
  const cancelReservation = (businessId, locationId, token, bookId, reason) => {
    const formData = new FormData();
    formData.append("business_id", businessId);
    formData.append("location_id", locationId);
    formData.append("api_key", api_key);
    formData.append("token", token);
    formData.append("book_id", bookId);
    formData.append("reason", reason);
    return restaurantApi.post("cancelreservation.php", formData);
  };

  return {
    findATable,
    getSessionId,
    reserveTable,
    findTimeSlots,
    findReservation,
    cancelReservation,
  };
};

export default {
  create,
};
