import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const ORDER_TYPES = [
  {
    type: "Delivery",
    id: 1,
  },
  {
    type: "Pickup",
    id: 2,
  },
];

// Types and actions creator

const { Types, Creators } = createActions({
  loginRequest: [
    "username",
    "password",
    "nextScreen",
    "closeFunction",
    "currentUrl",
  ],
  forgotPassword: ["email", "closeFunction"],
  loginSuccess: ["userInfo"],
  loginError: {},
  signupRequest: [
    "name",
    "phone",
    "email",
    "password",
    "nextScreen",
    "closeFunction",
    "currentUrl",
  ],
  resetPassword: ["approveKey", "password", "closeFunction"],
  signupSuccess: ["responseObj"],
  signupError: ["errorMessage"],
  logOut: {},
  setAddress: ["selectedAddress", "isScheduled", "scheduleDateTime"],
  setOrderType: ["selectedOrderType"],
  setPastAddressAndCard: ["past_address", "past_card"],
  setIsScheduled: ["isScheduled"],
  setScheduleDateTime: ["dateTime"],
  resetPasswordSuccess: {},
});

export const LoginStateTypes = Types;
export default Creators;

// Initial State
export const INITIAL_LOGIN_STATE = Immutable({
  isLoggedIn: false,
  userInfo: {},
  loginError: false,
  signupError: false,
  signupErrorMessage: "",
  loading: false,
  addressSelected: null,
  orderType: "2",
  token: "",
  past_address_list: [],
  past_card_list: [],
  isScheduled: false,
  scheduleDateTime: "",
  isStoreLogin: true,
  resetPassword: false,
});

// Selectors to derive data from state
export const LoginSelectors = {
  isUserloggedIn: (state) => {
    return state.login.isLoggedIn;
  },
  getSelectedAddress: (state) => state.login.addressSelected,
  getOrderType: (state) =>
    ORDER_TYPES.find(({ id }) => id == state.login.orderType).type,
  getOrderTypeId: (state) => state.login.orderType,
  getAllOrderTypes: (state) => ORDER_TYPES,
  getUserInfo: (state) => state.login.userInfo,
  getUserToken: (state) => state.login.token,
  getPastAddressList: (state) => state.login.past_address_list,
  getPastCardList: (state) => state.login.past_card_list,
  // getScheduledDateTime: state => {
  //   return state.login.isScheduled
  //     ? state.login.scheduleDateTime.getDate() == new Date().getDate()
  //       ? 'Today ' + moment(state.login.scheduleDateTime).format('LT')
  //       : moment(state.login.scheduleDateTime).format('DD MMM LT')
  //     : 'ASAP';
  // },
  getIsScheduled: (state) => state.login.isScheduled,
  getScheduledDateTime: (state) => state.login.scheduleDateTime,
  isLoading: (state) => state.login.loading,
  getLoginError: (state) => state.login.loginError,
  getSignupError: (state) => state.login.signupError,
  getSignupErrorMessage: (state) => state.login.signupErrorMessage,
  getStoreLoginStatus: (state) => state.login.isStoreLogin,
  getResetPassword: (state) => state.login.resetPassword,
};

// Reducers

export const loginRequest = (
  state,
  { username, password, nextScreen, closeFunction, currentUrl }
) => {
  console.log("Set loginRequest called");

  return {
    loading: true,
    isLoggedIn: false,
    userInfo: {},
    loginError: false,
    signupError: false,
    signupErrorMessage: "",
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    past_address_list: [],
    past_card_list: [],
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const loginSuccess = (state, responseObj) => {
  console.log("Set loginSuccess called");

  return {
    loading: false,
    isLoggedIn: true,
    userInfo: responseObj.userInfo,
    loginError: false,
    signupError: false,
    signupErrorMessage: "",
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: responseObj.userInfo.token,
    past_address_list: responseObj.userInfo.address_list,
    past_card_list: responseObj.userInfo.card_list,
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const loginError = (state) => {
  console.log("Set loginError called");

  return {
    loading: false,
    isLoggedIn: false,
    userInfo: {},
    loginError: true,
    signupError: false,
    signupErrorMessage: "",
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    past_address_list: [],
    past_card_list: [],
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const forgotPassword = (state, email, closeFunction) => {
  console.log("Set signupRequest called");

  return {
    loading: true,
    isLoggedIn: false,
    userInfo: {},
    loginError: false,
    signupError: false,
    signupErrorMessage: "",
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    past_address_list: [],
    past_card_list: [],
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const resetPassword = (state, approveKey, password, closeFunction) => {
  console.log("Set signupRequest called");

  return {
    loading: true,
    isLoggedIn: false,
    userInfo: {},
    loginError: false,
    signupError: false,
    signupErrorMessage: "",
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    past_address_list: [],
    past_card_list: [],
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const resetPasswordSuccess = (state) => {
  return {
    loading: false,
    isLoggedIn: false,
    userInfo: {},
    loginError: false,
    signupError: false,
    signupErrorMessage: "",
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    past_address_list: [],
    past_card_list: [],
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: true,
  };
};

export const signupRequest = (
  state,
  { name, phone, email, password, nextScreen, closeFunction, currentUrl }
) => {
  console.log("Set signupRequest called");

  return {
    loading: true,
    isLoggedIn: false,
    userInfo: {},
    loginError: false,
    signupError: false,
    signupErrorMessage: "",
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    past_address_list: [],
    past_card_list: [],
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const signupSuccess = (state, responseObj) => {
  console.log("Set signupSuccess called");

  return {
    loading: true,
    isLoggedIn: false,
    userInfo: {},
    loginError: false,
    signupError: false,
    signupErrorMessage: "",
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    past_address_list: [],
    past_card_list: [],
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const signupError = (state, errorMessage) => {
  console.log("Set signupError called");

  return {
    loading: false,
    isLoggedIn: false,
    userInfo: {},
    loginError: false,
    signupError: true,
    signupErrorMessage: errorMessage,
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    past_address_list: [],
    past_card_list: [],
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const logoutUser = (state) => {
  console.log("Set logoutUser called");

  return {
    loading: false,
    isLoggedIn: false,
    userInfo: {},
    loginError: false,
    signupError: false,
    signupErrorMessage: "",
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    past_address_list: [],
    past_card_list: [],
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const logOut = (state) => {
  return {
    loading: false,
    isLoggedIn: false,
    userInfo: {},
    loginError: false,
    signupError: false,
    signupErrorMessage: "",
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: "",
    past_address_list: [],
    past_card_list: [],
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const setAddress = (state, selectedAddress) => {
  console.log("Set Address called");
  return {
    loading: state.loading,
    isLoggedIn: state.isLoggedIn,
    userInfo: state.userInfo,
    loginError: state.loginError,
    signupError: state.signupError,
    signupErrorMessage: state.signupError,
    addressSelected: selectedAddress.selectedAddress,
    orderType: state.orderType,
    token: state.token,
    past_address_list: state.past_address_list,
    past_card_list: state.past_card_list,
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const setOrderType = (state, { selectedOrderType }) => {
  console.log("Set setOrderType called");
  return {
    loading: state.loading,
    isLoggedIn: state.isLoggedIn,
    userInfo: state.userInfo,
    loginError: state.loginError,
    signupError: state.signupError,
    signupErrorMessage: state.signupError,
    addressSelected: state.addressSelected,
    orderType: selectedOrderType,
    token: state.token,
    past_address_list: state.past_address_list,
    past_card_list: state.past_card_list,
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    isStoreLogin: state.isStoreLogin,
    resetPassword: false,
  };
};

export const setPastAddressAndCard = (state, { past_address, past_card }) => {
  console.log("Past Card", state);
  return {
    loading: state.loading,
    isLoggedIn: state.isLoggedIn,
    userInfo: state.userInfo,
    loginError: state.loginError,
    signupError: state.signupError,
    signupErrorMessage: state.signupError,
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: state.token,
    past_address_list: past_address,
    past_card_list: past_card,
    isScheduled: state.isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const setIsScheduled = (state, { isScheduled }) => {
  return {
    loading: state.loading,
    isLoggedIn: state.isLoggedIn,
    userInfo: state.userInfo,
    loginError: state.loginError,
    signupError: state.signupError,
    signupErrorMessage: state.signupError,
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: state.token,
    past_address_list: state.past_address,
    past_card_list: state.past_card,
    isScheduled: isScheduled,
    scheduleDateTime: state.scheduleDateTime,
    resetPassword: false,
  };
};

export const setScheduleDateTime = (state, { dateTime }) => {
  return {
    loading: state.loading,
    isLoggedIn: state.isLoggedIn,
    userInfo: state.userInfo,
    loginError: state.loginError,
    signupError: state.signupError,
    signupErrorMessage: state.signupError,
    addressSelected: state.addressSelected,
    orderType: state.orderType,
    token: state.token,
    past_address_list: state.past_address,
    past_card_list: state.past_card,
    isScheduled: state.isScheduled,
    scheduleDateTime: dateTime,
    resetPassword: false,
  };
};

// Setting reducers to the types

export const reducer = createReducer(INITIAL_LOGIN_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_ERROR]: loginError,
  [Types.SIGNUP_REQUEST]: signupRequest,
  [Types.SIGNUP_SUCCESS]: signupSuccess,
  [Types.FORGOT_PASSWORD]: forgotPassword,
  [Types.RESET_PASSWORD]: resetPassword,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.SIGNUP_ERROR]: signupError,
  [Types.LOG_OUT]: logOut,
  [Types.SET_ADDRESS]: setAddress,
  [Types.SET_ORDER_TYPE]: setOrderType,
  [Types.SET_PAST_ADDRESS_AND_CARD]: setPastAddressAndCard,
  [Types.SET_IS_SCHEDULED]: setIsScheduled,
  [Types.SET_SCHEDULE_DATE_TIME]: setScheduleDateTime,
});

// setAddress: ['selectedAddress'],
// setOrderType: ['selectedOrderType']
