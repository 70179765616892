import React, { Component } from "react";
import { withRouter, Router } from "react-router";
import { connect } from "react-redux";
import LoginActions, { LoginSelectors } from "../../Redux/LoginRedux";
import ReserveActions, { ReserveSelectors } from "../../Redux/ReserveRedux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Box,
  Button,
  TextField,
  MenuItem,
  Divider,
  Modal,
  Menu,
  IconButton,
  Card,
  CardContent,
} from "@material-ui/core";
import styles from "./style.module.scss";
import { Helmet } from "react-helmet";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DateFnsUtils from "@date-io/moment";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ReserveApi from "../../Services/reserveApi";
import RestaurantActions, {
  RestaurantSelectors,
} from "../../Redux/RestaurantRedux";
import LoginSignup from "../../components/LoginSignup/LoginSignup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendar, faUser } from "@fortawesome/free-solid-svg-icons";
import Timer from "react-compound-timer";
import Loading from "../../components/Loading/Loading";
import AccountCircle from "@material-ui/icons/AccountCircle";
import background from "../../assets/images/background.png";
import background1 from "../../assets/images/background1.png";
import background3 from "../../assets/images/background3.png";
import "react-add-to-calendar/dist/react-add-to-calendar.css";
import AddToCalendar from "react-add-to-calendar";
import { withTheme } from "@material-ui/core/styles";

import Swal from "sweetalert2";

class Reservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationInfo: null,
      loading: false,
      reservationCall: false,
      event: null,
      showloginModal: false,
    };
  }

  async cancelReservation() {
    var value;
    Swal.fire({
      text:
        "Please provide the reason for cancellation. This will help us to serve our guests experience better in the future!",
      input: "select",
      inputOptions: {
        "Change of Plans": "Change of Plans",
        "Change of date or time": "Change of date or time",
        Illness: "Illness",
        "Due to bad weather": "Due to bad weather",
      },
      inputPlaceholder: "Select a reason for cancellation",
      showCancelButton: true,
    }).then((result) => {
      console.log(result);
      if (result.value) {
        let apiService = ReserveApi.create();
        apiService
          .cancelReservation(
            this.props.restaurantDetails.businessId,
            this.props.restaurantDetails.locationId,
            this.props.userToken,
            this.state.bookId,
            result.value
          )
          .then((response) => {
            if (response.ok && response.data.status !== "Failure") {
              console.log("Cancelled");
              Swal.fire(
                "Cancelled!",
                "Your reservation has been cancelled!",
                "success"
              );
              this.props.history.push(
                `/web/${this.props.match?.params?.restaurant}/${this.props.match?.params?.location}`
              );
            } else if (response.ok && response.data.status === "Failure") {
              toast.error(response.data.status_message);
            } else {
              toast.error("Error occured during cancellating reservation!");
            }
          });
      }
    });
  }

  componentDidMount() {
    const restaurantDomain = this.props.match?.params?.restaurant;
    const locationDomain = this.props.match?.params?.location;
    const bookId = this.props.match?.params?.bookId;
    this.props.dispatch(
      RestaurantActions.setRestaurantDetails(restaurantDomain, locationDomain)
    );
    if (bookId) {
      this.setState({
        loading: true,
      });
    }
  }

  getReservationDetails = () => {
    this.setState({
      reservationCall: true,
    });
    if (!this.state.bookId && this.props.match?.params?.bookId) {
      let apiService = ReserveApi.create();
      apiService
        .findReservation(
          this.props.restaurantDetails.businessId,
          this.props.restaurantDetails.locationId,
          this.props.userToken,
          this.props.match?.params?.bookId
        )
        .then((response) => {
          if (response.ok && response.data.status !== "Failure") {
            if (response.data.book_id) {
              const startDate = moment(
                response.data.date + " " + response.data.time
              );
              const endDate = moment(
                response.data.date + " " + response.data.time
              ).add(90, "minutes");
              var event = {
                title:
                  this.props.restaurantDetails.businessName + " Reservation",
                description: this.props.restaurantDetails.messageFromRestaurant,
                location:
                  this.props.restaurantDetails.businessAddress +
                  ", " +
                  this.props.restaurantDetails.businessCity +
                  ", " +
                  this.props.restaurantDetails.businessProvince,
                startTime: startDate,
                endTime: endDate,
              };
              this.setState({
                reservationInfo: response.data,
                bookId: this.props.match?.params?.bookId,
                loading: false,
                event: event,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          }
        });
    }
  };

  setThemeColor = () => {
    if (
      this.props.restaurantDetails.themeColor &&
      this.props.restaurantDetails.themeColor !==
        this.props.theme.palette.secondary.main
    ) {
      this.props.onThemeColorChange(this.props.restaurantDetails.themeColor);
    }
  };

  checkLogin = () => {
    if (!this.props.isUserLoggedIn && !this.state.showloginModal) {
      this.setState({
        showloginModal: true,
        loading: false,
      });
    } else if (!this.props.isUserLoggedIn && this.state.showloginModal) {
    } else {
      if (!this.state.reservationCall) {
        this.getReservationDetails();
      }
    }
  };

  render() {
    this.setThemeColor();
    this.checkLogin();
    let icon = { "calendar-plus-o": "left" };
    return (
      <>
        {this.props.restaurantDetails?.businessName && (
          <Helmet>
            <title>
              {this.props.restaurantDetails?.businessName} | Order On The Go
            </title>
          </Helmet>
        )}
        {!this.props.restaurantDetails?.businessName && (
          <Helmet>
            <title>
              Order On The Go | Takeout and Delivery from your favorite
              restaurants
            </title>
          </Helmet>
        )}
        <Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={
              this.props.restaurantDetails.backgroundImage
                ? styles.restaurantBackgroundImage
                : styles.backgroudImage
            }
            style={{
              backgroundImage: this.props.restaurantDetails.backgroundImage
                ? "url(" + this.props.restaurantDetails.backgroundImage + ")"
                : "url(" + background3 + ")",
            }}
          >
            <Grid container justify="center" className={styles.mainSection}>
              <Grid item xs={12} className={styles.topSection}>
                <Grid container justify="center" alignItems="center">
                  {this.props.restaurantDetails?.logo && (
                    <Grid item xs={12}>
                      <div style={{ textAlign: "center" }}>
                        <img
                          className={styles.logo}
                          src={this.props.restaurantDetails?.logo}
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {this.props.restaurantDetails?.logoHasBusinessName !==
                      "1" && (
                      <h1 style={{ textAlign: "center" }}>
                        {this.props.restaurantDetails?.businessName}
                      </h1>
                    )}
                    <span style={{ textAlign: "center" }}>
                      {this.props.restaurantDetails?.businessLocation}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              {this.state.loading && (
                <Grid item xs={12}>
                  <div style={{ marginBottom: 35 }}>
                    <Loading />
                  </div>
                </Grid>
              )}
              {!this.state.loading && this.state.bookId && (
                <>
                  <Grid item xs={12} className={styles.topSection}>
                    <Grid container justify="center" alignItems="center">
                      <Grid item xs={12} alignItems="center">
                        <div
                          className="font16Bold"
                          style={{
                            textAlign: "center",
                            marginBottom: 15,
                          }}
                        >
                          Your reservation is on{" "}
                          {moment(this.state.reservationInfo?.date).format(
                            "dddd, DD MMM YYYY"
                          )}{" "}
                          at {this.state.reservationInfo?.time_slot} for{" "}
                          {this.state.reservationInfo?.guest} people
                        </div>
                        <div style={{ textAlign: "center", marginBottom: 15 }}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => this.cancelReservation()}
                          >
                            Cancel My Reservation
                          </Button>
                        </div>
                        <Divider />
                        <div
                          className="font20Bold"
                          style={{
                            textAlign: "center",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          {this.props.restaurantDetails.businessName}
                        </div>
                        <div
                          className="font16Bold"
                          style={{
                            textAlign: "center",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <a
                            className={styles.addressLink}
                            target="_blank"
                            href={`https://www.google.com/maps/search/?api=1&query=${this.props.restaurantDetails.businessName} ${this.props.restaurantDetails.businessAddress}`}
                          >
                            <div>
                              {this.props.restaurantDetails.businessAddress}
                            </div>
                            <div>
                              {this.props.restaurantDetails.businessCity},{" "}
                              {this.props.restaurantDetails.businessProvince},{" "}
                              {this.props.restaurantDetails.businessPostalCode}
                            </div>
                          </a>
                          {this.props.restaurantDetails.restaurantMessage && (
                            <>
                              <Grid item xs={12}>
                                <Card
                                  classes={{
                                    root: styles.allergyWarningStyle,
                                  }}
                                  elevation={0}
                                >
                                  <CardContent>
                                    <Grid
                                      container
                                      justify="center"
                                      alignItems="top"
                                    >
                                      <Grid item xs={12}>
                                        <>
                                          <div
                                            className={
                                              styles.messageFromRestaurant
                                            }
                                          >
                                            Message From{" "}
                                            {
                                              this.props.restaurantDetails
                                                .businessName
                                            }
                                          </div>
                                          <div>
                                            {
                                              this.props.restaurantDetails
                                                .restaurantMessage
                                            }
                                          </div>
                                        </>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {!this.state.loading && !this.state.bookId && (
                <Grid item xs={12} className={styles.topSection}>
                  <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} alignItems="center">
                      <div
                        className="font20Bold"
                        style={{ textAlign: "center" }}
                      >
                        Reservation Not Found!
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Modal open={this.state.showloginModal} disableBackdropClick>
            <div className={styles.loginModalStyle}>
              <LoginSignup
                nextScreen="null"
                onClose={() => this.setState({ showloginModal: false })}
              />
            </div>
          </Modal>
        </Box>
        {/* <Switch>
          <Route exact path={`${this.props.match.path}/order`} component={OrderScreen} />
          <Route exact path={`${this.props.match.path}/checkout`} component={Checkout} />
          <Route exact path={`${this.props.match.path}/orderHistory`} component={OrderHistory} />
        </Switch> */}
      </>

      // <Button variant="contained" onClick={() => this.goToMenuScreen()}>Get Menu</Button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isUserLoggedIn: LoginSelectors.isUserloggedIn(state),
    restaurantDetails: RestaurantSelectors.getRestaurantDetails(state),
    reserveDetails: ReserveSelectors.getReserveDetails(state),
    userInfo: LoginSelectors.getUserInfo(state),
    userToken: LoginSelectors.getUserToken(state),
  };
};

export default withRouter(connect(mapStateToProps)(withTheme(Reservation)));
